(window as any).__Zone_disable_requestAnimationFrame = true;
// (window as any).__Zone_disable_timers = true;
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove'];
(window as any).__Zone_disable_XHR = true;
(window as any).__Zone_disable_blocking = true;
(window as any).__Zone_disable_IntersectionObserver = true;
(window as any).__Zone_disable_geolocation = true;
(window as any).__Zone_disable_MutationObserver = true;
(window as any).__zone_symbol__DISABLE_WRAPPING_UNCAUGHT_PROMISE_REJECTION =
  true;
